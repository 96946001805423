// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Lead from "../../../models/Lead.res.js";
import * as Rest from "../../Rest.res.js";

function exec(startDate, endDate, leadTypes, pageNum, sortBy, query) {
  var page = Lead.Dashboard.Index.page(pageNum);
  var query$1 = Lead.Dashboard.Index.query(query);
  var sortBy$1 = Lead.Dashboard.Index.sortBy(sortBy);
  var leadTypes$1 = Lead.Dashboard.Index.leadTypes(leadTypes);
  var dates = Lead.Dashboard.Index.dates(startDate, endDate);
  return Rest.$$fetch("/dashboard/leads?" + page + sortBy$1 + query$1 + leadTypes$1 + dates, "Get", {
              NAME: "Json",
              VAL: Lead.Dashboard.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Lead Not a pure module */
