// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactDatepicker from "../../../../../bindings/ReactDatepicker.res.js";
import * as JsxRuntime from "react/jsx-runtime";

var initialState = {
  startDate: undefined,
  endDate: undefined
};

function DashboardLeadsIndexDateRanges(props) {
  var updateEndDate = props.updateEndDate;
  var updateStartDate = props.updateStartDate;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (action.TAG === "UpdateStartDate") {
            var startDate = action._0;
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      startDate: startDate,
                      endDate: state.endDate
                    },
                    _1: updateStartDate(startDate)
                  };
          }
          var endDate = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    startDate: state.startDate,
                    endDate: endDate
                  },
                  _1: updateEndDate(endDate)
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var date = state.startDate;
  var date$1 = state.endDate;
  var date$2 = state.startDate;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Start Date:"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ReactDatepicker.TextField.make, {
                            date: date !== undefined ? Caml_option.valFromOption(date) : new Date(),
                            className: "min-w-32",
                            onChange: (function (date) {
                                dispatch({
                                      TAG: "UpdateStartDate",
                                      _0: Caml_option.some(date)
                                    });
                              })
                          }),
                      className: "text-sm"
                    }),
                JsxRuntime.jsx("div", {
                      children: "End Date:"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ReactDatepicker.TextField.make, {
                            date: date$1 !== undefined ? Caml_option.valFromOption(date$1) : new Date(),
                            minDate: Caml_option.some(date$2 !== undefined ? Caml_option.valFromOption(date$2) : new Date()),
                            className: "min-w-32",
                            onChange: (function (date) {
                                dispatch({
                                      TAG: "UpdateEndDate",
                                      _0: Caml_option.some(date)
                                    });
                              })
                          }),
                      className: "text-sm"
                    })
              ],
              className: "flex flex-row gap-4 items-center"
            });
}

var make = DashboardLeadsIndexDateRanges;

export {
  initialState ,
  make ,
}
/* Hooks Not a pure module */
