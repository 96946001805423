// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var dropdownTrigger = Cx.cx([
      "text-base",
      "w-44",
      "h-10"
    ]);

var dropdownBody = Cx.cx([
      "max-h-64",
      "overflow-y-auto",
      "flex",
      "flex-col",
      "gap-2"
    ]);

var dropdownBodyRow = Cx.cx([
      "flex",
      "items-start",
      "gap-2.5",
      "w-60",
      "mb-1",
      "text-left"
    ]);

var label = Cx.cx([
      "text-base",
      "font-normal",
      "leading-4",
      "m-0",
      "break-words"
    ]);

export {
  dropdownTrigger ,
  dropdownBody ,
  dropdownBodyRow ,
  label ,
}
/* dropdownTrigger Not a pure module */
