// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Lead from "../../../../routes/common/Routes_Lead.res.js";
import * as Routes_Agency from "../../../../routes/common/Routes_Agency.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";

function DashboardLeadsNavbar(props) {
  var leadName = props.leadName;
  var leadId = props.leadId;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return DashboardNavbarCss.underlinedLinkContainer;
    } else {
      return DashboardNavbarCss.linkContainer;
    }
  };
  var tmp;
  switch (currentLink) {
    case "Index" :
        tmp = DashboardNavbarCss.underlinedLinkItem;
        break;
    case "Edit" :
    case "Show" :
        tmp = DashboardNavbarCss.normalLinkItem;
        break;
    
  }
  var tmp$1;
  var exit = 0;
  var id;
  if (leadId !== undefined) {
    var id$1 = Caml_option.valFromOption(leadId);
    if (leadName !== undefined) {
      switch (currentLink) {
        case "Index" :
            tmp$1 = null;
            break;
        case "Edit" :
            id = id$1;
            exit = 1;
            break;
        case "Show" :
            tmp$1 = JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(A.make, {
                        href: Routes_Lead.Dashboard.show(id$1),
                        className: DashboardNavbarCss.underlinedLinkItem,
                        children: leadName + " (" + ID.toString(id$1) + ")"
                      }),
                  className: linkCss("Show")
                });
            break;
        
      }
    } else {
      switch (currentLink) {
        case "Edit" :
            id = id$1;
            exit = 1;
            break;
        case "Index" :
        case "Show" :
            tmp$1 = null;
            break;
        
      }
    }
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_Lead.Dashboard.edit(id),
                className: DashboardNavbarCss.underlinedLinkItem,
                children: "Edit Lead"
              }),
          className: linkCss("Edit")
        });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_Agency.Dashboard.index,
                                  className: tmp,
                                  children: "Leads"
                                }),
                            className: linkCss("Index")
                          }),
                      tmp$1
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardLeadsNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
