// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconCalendar from "../styleguide/icons/IconCalendar.res.js";
import Format from "date-fns/format";
import ReactDatepicker from "react-datepicker";
import * as JsxRuntime from "react/jsx-runtime";

import 'react-datepicker/dist/react-datepicker.css'
;

var DatePicker = {};

var textField = Cx.cx([
      "flex",
      "flex-row",
      "justify-between",
      "items-center",
      "w-full",
      "border",
      "border-gray-300",
      "rounded-sm",
      "py-3.5",
      "px-2.5"
    ]);

var Css = {
  textField: textField
};

function ReactDatepicker$TextField(props) {
  var __className = props.className;
  var date = props.date;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx(ReactDatepicker, {
              selected: date,
              customInput: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          Format(date, "MM/dd/yyyy"),
                          JsxRuntime.jsx(IconCalendar.make, {
                                size: "SM",
                                color: "Gray"
                              })
                        ],
                        className: Cx.cx([
                              textField,
                              className
                            ])
                      })),
              minDate: props.minDate,
              onChange: props.onChange
            });
}

var TextField = {
  make: ReactDatepicker$TextField
};

export {
  DatePicker ,
  Css ,
  TextField ,
}
/*  Not a pure module */
