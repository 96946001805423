// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          email: js.email,
          phone: js.phone
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              phone: field.required("phone", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var LeadType = {};

function fromJs$1(js) {
  return {
          id: js.id,
          name: js.name,
          email: js.email,
          company: js.company,
          leadType: js.leadType,
          referrer: Caml_option.nullable_to_opt(js.referrer),
          pipedriveLeadId: Caml_option.nullable_to_opt(js.pipedriveLeadId),
          pipedriveDealId: Caml_option.nullable_to_opt(js.pipedriveDealId),
          pipedriveLeadUrl: Caml_option.nullable_to_opt(js.pipedriveLeadUrl),
          pipedriveDealUrl: Caml_option.nullable_to_opt(js.pipedriveDealUrl),
          createdAt: js.createdAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              company: field.required("company", Json_Decode$JsonCombinators.string),
              leadType: field.required("leadType", Json_Decode$JsonCombinators.string),
              referrer: field.required("referrer", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              pipedriveLeadId: field.required("pipedriveLeadId", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              pipedriveDealId: field.required("pipedriveDealId", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              pipedriveLeadUrl: field.required("pipedriveLeadUrl", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              pipedriveDealUrl: field.required("pipedriveDealUrl", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          leads: Belt_Array.map(js.leads, fromJs$1),
          totalLeads: js.totalLeads,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              leads: field.required("leads", Json_Decode$JsonCombinators.array(decoder$1)),
              totalLeads: field.required("totalLeads", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function query(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function leadTypes(leadTypes$1) {
  if (leadTypes$1.length !== 0) {
    return "&" + Belt_Array.map(leadTypes$1, (function (leadType) {
                    return "lead_types[]=" + leadType;
                  })).join("&");
  } else {
    return "";
  }
}

function dates(startDate, endDate) {
  if (startDate === undefined) {
    return "";
  }
  var start = Caml_option.valFromOption(startDate);
  if (endDate !== undefined) {
    return "&start_date=" + start.toISOString() + "&end_date=" + Caml_option.valFromOption(endDate).toISOString();
  } else {
    return "&start_date=" + start.toISOString() + "&end_date=" + new Date().toISOString();
  }
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "CreatedAt") {
    return "&sort_by=leads.created_at&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "LeadType") {
    return "&sort_by=leads.lead_type&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "Email") {
    return "&sort_by=leads.email&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "Name") {
    return "&sort_by=leads.name&sort_direction=" + toString(sortBy$1.VAL);
  } else {
    return "&sort_by=leads.company&sort_direction=" + toString(sortBy$1.VAL);
  }
}

var Index = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  query: query,
  leadTypes: leadTypes,
  dates: dates,
  sortBy: sortBy
};

var LeadType$1 = {};

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1,
  Index: Index,
  LeadType: LeadType$1
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  LeadType ,
  Dashboard ,
}
/* decoder Not a pure module */
