// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardLeadsLeadTypesCss from "./DashboardLeadsLeadTypesCss.res.js";

var initialState = {
  selectedLeadTypes: []
};

function DashboardLeadsLeadTypes(props) {
  var updateLeadTypes = props.updateLeadTypes;
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          var leadTypes = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    selectedLeadTypes: leadTypes
                  },
                  _1: updateLeadTypes(leadTypes)
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var x = state.selectedLeadTypes.length;
  return JsxRuntime.jsxs(Dropdown.make, {
              children: [
                JsxRuntime.jsx(Dropdown.Trigger.make, {
                      className: DashboardLeadsLeadTypesCss.dropdownTrigger,
                      iconSize: "XS",
                      iconColor: "Gray",
                      children: x !== 0 ? (
                          x !== 1 ? String(x) + " Lead Types" : "1 Lead Type"
                        ) : "All Lead Types"
                    }),
                JsxRuntime.jsx(Dropdown.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: DashboardLeadsLeadTypesCss.dropdownBody,
                      children: Belt_Array.mapWithIndex(props.leadTypes, (function (index, leadType) {
                              var id = "lead-leadType-filter-" + leadType.label + "-" + String(index);
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Checkbox.make, {
                                                  id: id,
                                                  size: "SM",
                                                  checked: (function (__x) {
                                                        return Js_array.findIndex((function (value) {
                                                                      return value === leadType.label;
                                                                    }), __x);
                                                      })(state.selectedLeadTypes) > -1,
                                                  onChange: (function ($$event) {
                                                      var checked = $$event.target.checked;
                                                      var leadTypes = checked ? Belt_Array.concat(state.selectedLeadTypes, [leadType.label]) : Belt_Array.keep(state.selectedLeadTypes, (function (value) {
                                                                return value !== leadType.label;
                                                              }));
                                                      dispatch({
                                                            TAG: "UpdateLeadTypes",
                                                            _0: leadTypes
                                                          });
                                                    })
                                                }),
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: id,
                                                  className: DashboardLeadsLeadTypesCss.label,
                                                  children: leadType.label
                                                })
                                          ],
                                          className: DashboardLeadsLeadTypesCss.dropdownBodyRow
                                        }, id);
                            }))
                    })
              ]
            });
}

var Css;

var make = DashboardLeadsLeadTypes;

export {
  Css ,
  initialState ,
  make ,
}
/* Hooks Not a pure module */
