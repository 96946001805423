// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchLeadsDashboardRequest from "./requests/FetchLeadsDashboardRequest.res.js";

var Dashboard = {
  index: FetchLeadsDashboardRequest.exec
};

export {
  Dashboard ,
}
/* FetchLeadsDashboardRequest Not a pure module */
